<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <div class="modal-header">
        <h4 class="header-text">Закупка №{{ purchase.id }}</h4>
        <div
          :class="purchaseType === 'Товар' ? 'btn_type_active' : ''"
          style="margin-left: 500px"
          @click="changeType('Товар')"
        >
          Товар
        </div>
        <div
          :class="purchaseType === 'Услуга' ? 'btn_type_active' : ''"
          @click="changeType('Услуга')"
        >
          Услуга
        </div>
        <span
          style="margin-right: 10px; font-size: 17px; cursor: pointer"
          @click="$emit('showModal')"
          ><b-icon icon="x-lg"></b-icon
        ></span>
      </div>
      <div class="sub-header">
        <label for="contractor" class="label_inputs">Контрагент</label><br />
        <b-form-input
          id="contractor"
          v-model="purchaseEdit.contractor.name"
          class="modal__select"
          disabled
        />
      </div>
      <div v-if="purchaseType === 'Товар'" class="modal-body">
        <button
          v-for="(product, index) in purchaseEdit.products"
          :key="index"
          :class="productIndex === index ? 'btn-quantity-active' : 'btn-quantity'"
          @click="productIndex = index"
        >
          №{{ index + 1 }}
        </button>
        <div class="modal-columns">
          <div class="modal-column" style="margin-right: 40px">
            <label for="category" class="label_inputs">Категория товара</label><br />
            <b-form-input
              id="category"
              v-model="
                purchaseEdit.products[productIndex].purchase_field.purchase_category.category
              "
              class="modal__select"
              disabled
            />
            <label for="description" class="label_inputs" style="margin-bottom: 17px"
              >Описание</label
            >
            <b-form-textarea
              id="description"
              v-model="purchaseEdit.products[productIndex].description"
              placeholder="Введите описание характеристик"
              class="modal__input"
              disabled
              style="line-height: 20px"
              rows="6"
            />

            <label for="manufacturer" class="label_inputs">Производитель</label><br />
            <b-form-input
              id="manufacturer"
              v-model="purchaseEdit.products[productIndex].manufacturer"
              class="modal__select"
              disabled
            />

            <label for="condition" class="label_inputs">Состояние</label><br />
            <b-form-input
              id="condition"
              v-model="purchaseEdit.products[productIndex].condition"
              class="modal__select"
              disabled
            />
          </div>

          <div class="modal-column">
            <label for="product" class="label_inputs">Наименование товара</label><br />
            <b-form-input
              id="product"
              v-model="purchaseEdit.products[productIndex].purchase_field.name"
              class="modal__select"
              disabled
            />
            <label for="amount" class="label_inputs">Стоимость</label><br />
            <b-form-input
              id="amount"
              v-model="purchaseEdit.products[productIndex].amount"
              type="number"
              disabled
              placeholder="Введите полную сумму"
              class="modal__input"
              :formatter="numberFormat"
            />

            <label for="quantity" class="label_inputs">Количество</label><br />
            <div class="modal__inputs-quantity">
              <b-form-input
                id="quantity"
                v-model="purchaseEdit.products[productIndex].quantity"
                type="number"
                disabled
                placeholder="Введите количество"
                class="modal__input-quantity"
              />
              <b-form-input
                id="product"
                v-model="purchaseEdit.products[productIndex].unit"
                class="modal__select-quantity"
                disabled
                style="margin-left: 20px"
              />
            </div>
            <label for="country" class="label_inputs">Страна производителя</label><br />
            <b-form-input
              id="country"
              v-model="purchaseEdit.products[productIndex].country"
              class="modal__select"
              disabled
            />
            <label for="appointment" class="label_inputs">Назначение</label><br />
            <b-form-input
              id="appointment"
              v-model="appointmentProductData"
              class="modal__select"
              disabled
            />
          </div>
        </div>
      </div>

      <div class="modal-body" v-else>
        <button
          v-for="(service, index) in purchaseEdit.services"
          :key="index"
          :class="serviceIndex === index ? 'btn-quantity-active' : 'btn-quantity'"
          @click="serviceIndex = index"
        >
          №{{ index + 1 }}
        </button>
        <div class="modal-columns">
          <div class="modal-column" style="margin-right: 40px">
            <label for="category" class="label_inputs">Категория услуги</label><br />
            <b-form-input
              id="category"
              v-model="
                purchaseEdit.services[serviceIndex].purchase_field.purchase_category.category
              "
              class="modal__select"
              disabled
            /><br />

            <label for="description" class="label_inputs" style="margin-bottom: 20px"
              >Описание</label
            >
            <b-form-textarea
              id="description"
              v-model="purchaseEdit.services[serviceIndex].description"
              placeholder="Введите описание характеристик"
              class="modal__input"
              disabled
              style="line-height: 19px"
              rows="11"
            />

            <label for="appointment" class="label_inputs">Назначение</label><br />
            <b-form-input
              id="appointment"
              v-model="appointmentServiceData"
              class="modal__select"
              disabled
            />
          </div>

          <div class="modal-column">
            <label for="service" class="label_inputs">Наименование услуги</label><br />
            <b-form-input
              id="service"
              v-model="purchaseEdit.services[serviceIndex].purchase_field.name"
              class="modal__select"
              disabled
            />
            <label for="amount" class="label_inputs" style="margin-top: 40px"
              >Ожидаемая сумма расхода</label
            ><br />
            <b-form-input
              id="amount"
              v-model="purchaseEdit.services[serviceIndex].amount"
              type="number"
              placeholder="Введите полную сумму"
              class="modal__input"
              disabled
            />

            <label for="hour" class="label_inputs">Срок реализации</label><br />
            <div class="modal__inputs-quantity">
              <b-form-input
                id="hour"
                v-model="purchaseEdit.services[serviceIndex].hour"
                type="number"
                placeholder="Часы"
                class="modal__input-quantity"
                disabled
              />
              <b-form-input
                id="hour"
                v-model="purchaseEdit.services[serviceIndex].day"
                type="number"
                placeholder="Дни"
                style="margin-left: 20px"
                class="modal__input-quantity"
                disabled
              />
            </div>
            <label for="responsible" class="label_inputs">Ответственный</label><br />
            <b-form-input
              id="responsible"
              v-model="purchaseEdit.services[serviceIndex].responsible"
              class="modal__select"
              disabled
            />
            <label for="quantity" class="label_inputs">Количество</label><br />
            <div class="modal__inputs-quantity">
              <b-form-input
                id="quantity"
                v-model="purchaseEdit.services[serviceIndex].quantity"
                type="number"
                placeholder="Введите количество"
                class="modal__input-quantity"
                disabled
              />
              <b-form-input
                id="product"
                v-model="purchaseEdit.services[serviceIndex].unit"
                class="modal__select-quantity"
                disabled
                style="margin-left: 20px"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <a
          class="add__product"
          :href="$url + '/api/web/purchase/download-invoice/' + purchase.path_file"
          target="_blank"
        >
          <svg
            width="19"
            style="margin-right: 5px"
            height="19"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16"
              stroke="#3F8AE0"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Скачать счет
        </a>
        <div v-if="showButtons(profileProducts.position.title)" class="buttons-block">
          <div class="agree-buttons">
            <button class="header__btn default-btn modal__button1" @click="sendStatus('canceled')">
              Отменить
            </button>
            <button class="header__btn default-btn modal__button2" @click="sendStatus('agreed')">
              Согласовать
            </button>
          </div>
          <div class="agree-buttons2">
            <button
              class="header__btn default-btn modal__question-btn"
              @click="sendStatus('questioned')"
            >
              Отправить вопрос
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    purchase: Object,
  },
  data() {
    return {
      productIndex: 0,
      serviceIndex: 0,
      date: null,
      dateShow: false,
      purchaseData: {},
      indexItem: 0,
      purchaseType: !this.purchase.products.length ? "Услуга" : "Товар",
      statuses: ["agreed_analytic", "agreed_chief", "consideration", "answered"],
    };
  },
  methods: {
    changeType(type) {
      if (type === "Товар" && this.purchase.products.length) {
        this.purchaseType = "Товар";
      } else if (this.purchase.services.length) {
        this.purchaseType = "Услуга";
      }
    },
    sendStatus(status) {
      let comment = prompt("Оставьте комментарий", "");
      if (comment) {
        this.$api
          .post("/web/purchase/update/" + this.purchase.id, {
            comment: comment,
            user_id: this.$store.state.profileProducts.id,
            status: status,
          })
          .then(() => {
            this.$emit("search");
            this.$emit("showModal");
            this.$toast.success("Успешно");
          })
          .catch(() => {
            this.$toast.error("Что-то пошло не так, свяжитесь с администратором");
          });
      }
    },
    showButtons(position) {
      if (
        position == "chief_mechanic" ||
        position == "accountant" ||
        position == "director" ||
        position == "admin"
      ) {
        return true;
      }

      // return false;
    },
    numberFormat(value) {
      if (value.length > 0) {
        value = value.replace(/[^.0-9]/g, "");

        if (value.startsWith("-")) {
          value = "-" + value.replace(/-/g, "");
        }

        if ((value.match(/\./g) || []).length > 1) {
          value = value.slice(0, value.lastIndexOf("."));
        }

        if (value.includes(".") && !value.includes("-")) {
          let parts = value.split(".");

          value = parts[0] + "." + parts[1].replace(/\./g, "");
        }

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    },
  },
  computed: {
    ...mapState(["profileProducts"]),
    purchaseEdit() {
      return this.purchase;
    },
    appointmentServiceData() {
      return this.purchaseEdit.services[this.serviceIndex].transport
        ? this.purchaseEdit.services[this.serviceIndex].transport.label
        : "На склад";
    },
    appointmentProductData() {
      let transport = this.purchase.products[this.productIndex].transport;
      return transport ? transport.mark_model + " | " + transport.number : "На склад";
    },
  },
  async created() {
    await this.$store.dispatch("getProfileProducts");
    // console.log(this.purchase);
  },
};
</script>

<style scoped>
.modal-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow-y: scroll;
  transform: translateY(0%);
}
.modal-container {
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  border-radius: 20px;
  max-width: 100%;
  min-width: 300px;
  z-index: 12;
}
.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: none;
}
.header-text {
  margin-left: 10px;
  margin-top: 10px;
}
.sub-header {
  margin-left: 15px;
}
.btn_form_active {
  background: #ffffff;
  color: #1e98ff;
}
.btn_form {
  background: #ffffff;
}
.btn_type_active {
  background-color: #dedede;
  padding: 8px;
  border-radius: 8px;
}
.modal__select {
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 400px;
}
.choices-block {
  border-bottom: 2px solid #eee;
}
.modal-body {
  padding: 1rem;
}
.modal-columns {
  display: flex;
}
.modal-column {
  flex: 1;
}
.modal-footer {
  border-top: none;
  width: 100%;
  /* border: 1px solid black; */
}
.modal__input {
  width: 400px;
  border-radius: 10px;
  color: #acacac;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.modal__inputs-quantity {
  width: 100%;
}
.modal__select-quantity {
  display: inline-block;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 190px;
}
.modal__input-quantity {
  width: 190px;
  border-radius: 10px;
  color: #acacac;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: inline-block;
}
.label_inputs {
  font-weight: 400;
  display: flex;
  margin-top: 20px;
}

.buttons-block {
  width: 96%;
  margin: auto;
  margin-top: 15px;
}

.agree-buttons {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 0;
  width: 100%;
}

.agree-buttons2 {
  text-align: center;
  padding: 0;
  margin: 0;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.modal__button1 {
  width: 48%;
  background: #f2f2f8;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: black;
  margin: 0;
}

.modal__button2 {
  width: 48%;
  background: #e0e9fa;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366d6;
  margin: 0;
}

.modal__question-btn {
  width: 96%;
  background: #e0e9fa;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #0366d6;
  margin: 0;
}

.add__product {
  width: 96%;
  font-weight: 500;
  font-size: 16px;
  line-height: 128%;
  color: #0366d6;
  display: flex;
  margin: auto;
  margin-top: 16px;
  padding: 20px;
  align-items: center;
  border: 2px solid #0366d6;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
}
.inputAdd {
  border: 4px dashed #4a504c;
  z-index: 1000;
}
.btn-quantity {
  width: 60px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #777777;
  border-radius: 0;
}
.btn-quantity-active {
  width: 60px;
  height: 40px;
  background-color: #e0e9fa;
  color: #0366d6;
  border: 1px solid #777777;
  border-radius: 0;
}
.delete-item {
  float: right;
  background-color: #fff;
  color: #0366d6;
  line-height: 40px;
}
</style>
